import React, { useState } from "react";
import { Card } from "../../../common/Card";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import { toast } from "react-toastify";
import { CorporateBookingRestService } from "../../../../services/CorporateBookingRestService";
import { useRecoilState } from "recoil";
import { corporateBookingSeatCodesSelector } from "../../CorporateBookingState";

export const BalestierDreamerSeatPlan = (props) => {
  const {
    guestCount,
    performanceId,
    preSelectedSeats,
    readOnly,
    corporateBookingId,
  } = props;
  const [selectedSeats, setSelectedSeats] = React.useState([]);
  const [, setSeatCodes] = useRecoilState(corporateBookingSeatCodesSelector);
  const [sessionGuid] = useState(
    crypto
      ? crypto.randomUUID()
      : `${Math.floor(Math.random() * 90000) + 10000}`
  );

  const onAutoSelect = () => {
    const payload = {
      performanceId,
      numberOfSeats: guestCount,
      corporateBookingId,
      sessionGuid,
    };
    new CorporateBookingRestService()
      .autoSelectSeats(payload)
      .then((response) => {
        const seats = response.data.map((x) => ({ seatCode: x }));
        setSelectedSeats(seats);
        setSeatCodes(response.data);
        toast.success(`${seats.length} seat(s) have been selected`);
      });
  };
  React.useEffect(() => {
    if (preSelectedSeats) {
      setSelectedSeats(preSelectedSeats);
    }
  }, [preSelectedSeats]);

  React.useEffect(() => {
    window.onbeforeunload = () => {
      new CorporateBookingRestService()
        .releaseSeat({ sessionGuid })
        .then(() => {});
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [sessionGuid]);

  return (
    <div className="row">
      <div className="col">
        <Card title="Balestier Dreamer Seat Selection">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <img
              style={{ height: 400 }}
              className="dreamers-seats-plan"
              src="../img/seatlayout/Dreamers-Seats-Plan-(Balestier)-1920x1080.jpg"
            />
            <p>
              Seat Count Selected: {selectedSeats.length} / {guestCount}
            </p>
          </div>
          {!readOnly && (
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                onClick={onAutoSelect}
              >
                Auto Select
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          )}
        </Card>
      </div>
    </div>
  );
};
