import React from "react";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import { EvoucherOrderMode } from "../common/EvoucherOrderMode";
import { EvoucherOrderCard } from "./cards/EvoucherOrderCard";
import { CancelEvoucherOrderDialog } from "./dialogs/CancelEvoucherOrderDialog";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import {
  EvoucherOrderStatus,
  GenericMessages,
  ObjectType,
} from "../../../common/Constants";
import { EvoucherBatchListingCard } from "./cards/EvoucherBatchListingCard";
import { EVoucherHistoryDialog } from "../../common/EVoucherHistoryDialog";
import { Link } from "react-router-dom";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { showLoading } from "../../../common/Common";
import { EVoucherOrderService } from "../../../services/EVoucherOrderService";

export class EvoucherOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: EvoucherOrderMode.View,
      generalInfo: null,
      generalInfoValidations: [],
      evoucherOrder: null,
      redirect: false,
      showCancelDialog: false,
      showConfirmDialog: false,
      showEVoucherOrderHistoryDialog: false,
      evoucherOrderId: "",
      evoucherProgramDetail: null,
      showSaveConfirmation: false,
      showRejectConfirmation: false,
      showApproveConfirmation: false,
      showCompleteOrderConfirmation: false,
    };
    this.eVoucherOrderService = new EVoucherOrderService();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherOrderId = StringHelper.toNumber(id, 0);
    if (evoucherOrderId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ evoucherOrderId }, this.refreshData);
  }

  refreshData() {
    const { evoucherOrderId } = this.state;
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_orders/${evoucherOrderId}`,
      { includes: "EVoucherProgram,EVoucherBatches" },
      (response) => {
        this.setState({ evoucherOrder: response });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  onGeneralInfoModelChange = (model) => {
    this.setState({ generalInfo: model });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onProceedCancelEvoucherOrderDialog = (reason) => {
    const { evoucherOrder } = this.state;
    const request = {
      id: evoucherOrder.id,
      reason,
    };
    RestClient.sendPostRequest(
      `/api/v1/evoucher_orders/${this.state.evoucherOrderId}/cancel`,
      request,
      (response) => {
        this.setState({
          cardMode: EvoucherOrderMode.View,
          isSubmit: false,
          evoucherOrder: response,
        });
        toast.success(
          "eVoucher Batch Extension Request has been canceled successfully."
        );
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
    this.setState({
      showCancelDialog: false,
    });
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    this.setState({ showSaveConfirmation: true });
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/evouchers/orders/${additionalParameter ?? ""}`);
  }

  onViewHistoryClick = () => {
    this.setState({ showEVoucherOrderHistoryDialog: true });
  };

  onEditOrderClick = () => {
    this.setState({ cardMode: EvoucherOrderMode.Update });
  };

  onHideHistoryDialog = () => {
    this.setState({ showEVoucherOrderHistoryDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showSaveConfirmation: false });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evoucher-order-details",
        name: EvoucherOrderMode.isViewMode(this.state.cardMode)
          ? "eVoucher Orders Details"
          : "Edit evoucher",
      }),
    });
  };

  onProceedConfirmDialog = async () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo } = this.state;
    const request = Object.assign(
      {
        eVoucherProgramId: Number(generalInfo.eVoucherProgram.id),
      },
      generalInfo
    );
    try {
      await this.eVoucherOrderService.put(this.state.evoucherOrderId, request);
      this.setState({
        showConfirmDialog: false,
      });
      toast.success("eVoucher Order has been updated successfully.");
      this.redirectToListingPage(
        "?created=eVoucherOrders has been updated successfully."
      );
    } catch (error) {
      this.setState({
        showConfirmDialog: false,
      });
      toast.error(error.message);
    }
  };

  onRejectOrder = (reason) => {
    RestClient.sendPostRequest(
      `/api/v1/evoucher_orders/${this.state.evoucherOrderId}/reject`,
      {
        reason,
      },
      () => {
        this.setState({
          showRejectConfirmation: false,
        });
        toast.success("eVoucher Order has been rejected.");
        this.redirectToListingPage(
          "?created=eVoucherOrders has been rejected."
        );
      },
      (errorResponse) => {
        this.setState({
          showRejectConfirmation: false,
        });
        toast.error(errorResponse.message);
        showLoading(false);
      }
    );
  };

  onApproveOrder = () => {
    const { evoucherOrderId } = this.state;
    RestClient.sendPostRequest(
      `/api/v1/evoucher_orders/${evoucherOrderId}/approve`,
      {},
      () => {
        this.setState({
          showApproveConfirmation: false,
        });
        toast.success("eVoucher Order has been approved.");
        this.redirectToListingPage(
          "?created=eVoucherOrders has been approved."
        );
      },
      (errorResponse) => {
        this.setState({
          showApproveConfirmation: false,
        });
        showLoading(false);
        toast.error(errorResponse.message);
      }
    );
  };

  onShowCompleteOrderDialog = () => {
    this.setState({ showCompleteOrderConfirmation: true });
  };

  getCompleteOrderMessage = () => {
    const { isCompleted, totalQuantity, issuedQuantity } =
      this.state.generalInfo || {};
    const statusText = isCompleted ? `<br/>"Not Completed"` : `"Completed"`;
    const remainingBalance = totalQuantity - issuedQuantity;

    let message = `Are you sure you want to mark this order as ${statusText}?`;
    if (remainingBalance > 0 && !isCompleted) {
      message += ` Remaining eVouchers balance: ${remainingBalance}/${totalQuantity}`;
    }
    return message;
  };

  onCompleteOrderChange = async () => {
    const { evoucherOrderId, generalInfo } = this.state;
    const { isCompleted } = generalInfo;
    showLoading(true);
    const request = Object.assign(generalInfo, {
      eVoucherProgramId: Number(generalInfo.eVoucherProgram.id),
      isCompleted: !isCompleted,
    });
    const response = await new EVoucherOrderService((response) => {
      if (response.status === 403) {
        toast.error(`You do not have permission to perform this action.`);
        return true;
      }
      return false;
    }).put(evoucherOrderId, request);
    showLoading(false);
    if (response.status === 200) {
      this.setState({
        generalInfo: { ...generalInfo, isCompleted: response.data.isCompleted },
      });
    }
    this.setState({ showCompleteOrderConfirmation: false });
  };

  render() {
    const {
      isSubmit,
      evoucherOrder,
      showCancelDialog,
      generalInfo,
      showEVoucherOrderHistoryDialog,
      showSaveConfirmation,
      showRejectConfirmation,
      showApproveConfirmation,
      showCompleteOrderConfirmation,
    } = this.state;
    const isPending =
      evoucherOrder && evoucherOrder.status === EvoucherOrderStatus.Pending;
    const isApproved =
      evoucherOrder && evoucherOrder.status === EvoucherOrderStatus.Approved;
    const profile = AuthenticationManager.profile();
    const isOwner = generalInfo && generalInfo.createdBy === profile.username;
    const isApprover = AuthenticationManager.isAuthorized(
      ResourceID.EVoucherOrderApprover
    );
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup className="d-flex flex-row justify-content-end mb-3">
              {AuthenticationManager.isAuthorized(
                ResourceID.EVoucherOrderBatchCreateButton
              ) &&
                isApproved &&
                EvoucherOrderMode.isViewMode(this.state.cardMode) && (
                  <ButtonActionDetailGroup.Item>
                    <Link
                      className="btn btn-primary w-100"
                      to={{
                        pathname: "/evouchers/batches/create",
                        state: {
                          generalInfo: generalInfo,
                          orderId: this.state.evoucherOrderId,
                        },
                      }}
                      style={{ fontSize: "1rem" }}
                    >
                      <i className="fas fa-plus-circle" /> Create eVoucher Batch
                    </Link>
                  </ButtonActionDetailGroup.Item>
                )}
              {(isOwner ||
                AuthenticationManager.isAuthorized(
                  ResourceID.EvoucherOrderEditButton
                )) &&
                (isPending || isApproved) &&
                EvoucherOrderMode.isViewMode(this.state.cardMode) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-primary mr-1 mb-1"
                    onClick={this.onEditOrderClick}
                  >
                    Edit
                  </ButtonActionDetailGroup.Item>
                )}
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                onClick={this.onViewHistoryClick}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col-md-12">
                <EvoucherOrderCard
                  cardMode={this.state.cardMode}
                  defaultValue={evoucherOrder}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                  onShowCompleteOrderDialog={this.onShowCompleteOrderDialog}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end pb-3">
              {this.state.cardMode === EvoucherOrderMode.Update && (
                <button
                  className="btn btn-success mr-1 mb-1"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </button>
              )}

              {isApprover &&
                isPending &&
                !isOwner &&
                EvoucherOrderMode.isViewMode(this.state.cardMode) && (
                  <>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger mr-1 mb-1"
                      onClick={() =>
                        this.setState({ showRejectConfirmation: true })
                      }
                    >
                      Reject
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success mr-1 mb-1"
                      onClick={() =>
                        this.setState({ showApproveConfirmation: true })
                      }
                    >
                      Approve
                    </ButtonActionDetailGroup.Item>
                  </>
                )}

              {(isOwner ||
                AuthenticationManager.isAuthorized(
                  ResourceID.EvoucherOrderCancelOrderButton
                )) &&
                generalInfo &&
                generalInfo.issuedQuantity === 0 &&
                (isPending || isApproved) && (
                  <button
                    className="btn btn-outline-secondary mr-1 mb-1 mr-1 mb-1"
                    onClick={this.onShowCancelDialog}
                  >
                    Cancel Order
                  </button>
                )}
            </div>
            {generalInfo && generalInfo.eVoucherBatches.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <EvoucherBatchListingCard
                    cardMode={this.state.cardMode}
                    orderId={generalInfo.id}
                    evoucherbatches={generalInfo.eVoucherBatches}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {showCancelDialog && (
          <CancelEvoucherOrderDialog
            onCancel={this.onHideCancelDialog}
            onProceed={this.onProceedCancelEvoucherOrderDialog}
            description="Are you sure you want to cancel?"
            title="Confirmation"
          />
        )}
        {showRejectConfirmation && (
          <CancelEvoucherOrderDialog
            onCancel={() => this.setState({ showRejectConfirmation: false })}
            onProceed={this.onRejectOrder}
            description="Are you sure you want to reject this order?"
            title="Reject"
          />
        )}
        {showEVoucherOrderHistoryDialog && (
          <EVoucherHistoryDialog
            objectId={evoucherOrder.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.EvoucherOrder}
            getStatusColor={EvoucherOrderStatus.getColor}
          />
        )}
        <ConfirmDialog
          visible={showSaveConfirmation}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to save changes?"
          title="Save changes"
        />
        <ConfirmDialog
          visible={showApproveConfirmation}
          onCancel={() => this.setState({ showApproveConfirmation: false })}
          onProceed={this.onApproveOrder}
          message="Are you sure you want to approve this order?"
          title="Approve eVoucher Order"
        />
        <ConfirmDialog
          visible={showCompleteOrderConfirmation}
          onCancel={() =>
            this.setState({ showCompleteOrderConfirmation: false })
          }
          onProceed={this.onCompleteOrderChange}
          message={this.getCompleteOrderMessage()}
          title={"Complete eVoucher Order"}
        />
      </div>
    );
  }
}
