import React from "react";
import { EvoucherBatchMode } from "../../common/EvoucherBatchMode";
import { EVoucherBatchStatus } from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { DateHelper, preventNonNumericInput } from "../../../../common/Helpers";
import { EvoucherQuantityPreview } from "../../common/EvoucherQuantityPreview";
import { EvoucherProgramPreview } from "../../common/EvoucherProgramPreview";
import RestClient from "../../../../common/RestClient";
import moment from "moment";
import { IntegerInput } from "../../../common/IntegerInput";
import { RowFormField } from "../../../layout/FormLayout";

export class EvoucherBatchInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: this.mergeDefaultValue(props.generalInfo, props.orderId),
      generalInfo: props.generalInfo,
      validations: [],
      evoucherOrder: null,
    };
  }

  componentDidMount() {
    if (this.props.cardMode === EvoucherBatchMode.View) {
      this.loadEvoucherOrder();
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eVoucherBatchId !== this.props.eVoucherBatchId) {
      this.loadEvoucherOrder();
    }
    if (prevProps.isClear !== this.props.isClear) {
      this.setState({
        model: this.mergeDefaultValue(
          this.props.generalInfo,
          this.props.orderId
        ),
      });
    }
  }

  loadEvoucherOrder() {
    const { orderId } = this.props.evoucherBatchOrder || {};
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_orders/${orderId}`,
      { includes: "EVoucherProgram" },
      (response) => {
        if (response) {
          this.setState({
            evoucherOrder: response,
          });
        }
      }
    );
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    if (!this.props.generalInfo) return;

    const { eVoucherProgram } = this.props.generalInfo;
    return {
      evoucherOrderId: value.id || 0,
      totalQuantity: 0,
      validityStart: moment()
        .add(1, "day")
        .startOf("day")
        .format(DateHelper.IsoDateTimeFormat),
      expiryDate: moment()
        .add(eVoucherProgram.validityDuration + 1, "day")
        .endOf("day")
        .format(DateHelper.IsoDateTimeFormat),
    };
  }

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    if (fieldName === "validityStart") {
      const { eVoucherProgram } = this.state.generalInfo;
      let newModelWithExpiryDate = Object.assign({}, this.state.model, {
        [fieldName]: value,
        ["expiryDate"]: moment(value)
          .add(eVoucherProgram.validityDuration, "day")
          .endOf("day")
          .format(DateHelper.IsoDateTimeFormat),
      });
      this.setState(
        { isDirty: true, model: newModelWithExpiryDate },
        this.onModelChange
      );
    } else {
      this.setState({ isDirty: true, model: newModel }, this.onModelChange);
    }
  };

  onModelChange() {
    const { onModelChange } = this.props;
    if (onModelChange) {
      onModelChange(this.state.model);
    }
  }

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      onValidationsChange([...this.state.validations]);
    }
  };

  onValidationStatusChange = (e) => {
    const { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  render() {
    const renderMethods = {
      [EvoucherBatchMode.Create]: this.renderCreateMode.bind(this),
      [EvoucherBatchMode.View]: this.renderViewMode.bind(this),
    };
    return (
      <Card title="eVoucher Batch Details">
        {renderMethods[this.props.cardMode]()}
      </Card>
    );
  }

  renderViewMode() {
    const { evoucherOrder } = this.state;
    const { evoucherBatchOrder } = this.props;
    const quantities = {
      quantityColumn1: evoucherBatchOrder.totalQuantity,
      quantityColumn2: evoucherBatchOrder.usedQuantity,
    };
    const titles = {
      titleColumn1: "Total Batch Quantity",
      titleColumn2: "Total Redeemed",
    };

    if (!evoucherBatchOrder) return <></>;

    return (
      <div className="details row flex-column-reverse flex-xl-row">
        <div className="col-xl-6">
          <RowFormField label="Order ID:" htmlFor="OrderId">
            <input
              type="text"
              id="OrderId"
              className="form-control display-input"
              value={evoucherBatchOrder.orderIdCode}
              readOnly
            />
          </RowFormField>
          {evoucherOrder && (
            <RowFormField label="Total Order Quantity:" htmlFor="TotalQuantity">
              <input
                type="number"
                readOnly
                id="TotalQuantity"
                value={evoucherOrder.totalQuantity}
              />
            </RowFormField>
          )}
          {evoucherOrder && (
            <EvoucherProgramPreview
              evoucherProgramId={evoucherOrder.eVoucherProgram?.id}
            />
          )}
          <RowFormField label="Batch Id:" htmlFor="batchId">
            <input
              className="form-control display-input"
              fieldname="batchId"
              value={evoucherBatchOrder.reference}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Status:" htmlFor="status">
            <input
              className={EVoucherBatchStatus.getColor(
                evoucherBatchOrder.status
              )}
              fieldname="status"
              value={evoucherBatchOrder.status}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Batch Quantity:" htmlFor="BatchQuantity">
            <input
              className="form-control display-input"
              type="number"
              fieldname="totalQuantity"
              value={evoucherBatchOrder.totalQuantity}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Validity Start:" htmlFor="ValidityStart">
            <input
              className="form-control display-input"
              value={DateHelper.toDisplayDateTimeFormat(
                evoucherBatchOrder.validityStart
              )}
              fieldname="validityStart"
              id="validityStart"
              readOnly
            />
          </RowFormField>
          <RowFormField label="Expiry Date:" htmlFor="expiryDate">
            <input
              className="form-control display-input"
              id="expiryDate"
              fieldname="expiryDate"
              value={DateHelper.toDisplayDateTimeFormat(
                evoucherBatchOrder.validityEnd
              )}
              readOnly
            />
          </RowFormField>
          {evoucherBatchOrder.status === EVoucherBatchStatus.Approved && (
            <RowFormField label="Last Redemption On:" htmlFor="expiryDate">
              {evoucherBatchOrder.lastRedemptionOn && (
                <input
                  className="form-control display-input"
                  id="expiryDate"
                  fieldname="expiryDate"
                  value={DateHelper.toDisplayDateTimeFormat(
                    evoucherBatchOrder.lastRedemptionOn
                  )}
                  readOnly
                />
              )}
            </RowFormField>
          )}

          <RowFormField label="Created By:" htmlFor="createdBy">
            <input
              className="form-control display-input"
              id="createdBy"
              fieldname="expiryDate"
              value={evoucherBatchOrder.createdBy}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Created On:" htmlFor="createdOn">
            <input
              className="form-control display-input"
              id="createdOn"
              fieldname="expiryDate"
              value={DateHelper.toDisplayDateTimeFormat(
                evoucherBatchOrder.createdOn
              )}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Remaining Duration:" htmlFor="remainingDuration">
            <input
              className="form-control display-input"
              id="remainingDuration"
              fieldname="remainingDuration"
              value={Math.ceil(evoucherBatchOrder.remainingDuration) + " days"}
              readOnly
            />
          </RowFormField>
          {evoucherBatchOrder.status === EVoucherBatchStatus.Rejected && (
            <RowFormField
              label="Reason for rejection:"
              htmlFor="reasonForRejection"
            >
              <input
                className="form-control display-input"
                id="reasonForRejection"
                fieldname="reasonForRejection"
                value={evoucherBatchOrder.approvalReason}
                readOnly
              />
            </RowFormField>
          )}
          {evoucherBatchOrder.status === EVoucherBatchStatus.Cancelled && (
            <RowFormField
              label="Reason for cancellation:"
              htmlFor="reasonForRejection"
            >
              <input
                className="form-control display-input"
                id="reasonForRejection"
                fieldname="reasonForRejection"
                value={evoucherBatchOrder.approvalReason}
                readOnly
              />
            </RowFormField>
          )}
        </div>
        <EvoucherQuantityPreview
          imageLink={evoucherOrder?.eVoucherProgram?.urlImage}
          quantities={quantities}
          titles={titles}
        />
      </div>
    );
  }

  renderCreateMode() {
    const { isSubmitted } = this.props;
    const { generalInfo, model } = this.state;
    const {
      orderId,
      totalQuantity,
      issuedQuantity,
      pendingQuantity,
      eVoucherProgram,
    } = generalInfo;
    const quantities = {
      quantityColumn1: totalQuantity,
      quantityColumn2: issuedQuantity + pendingQuantity,
    };
    const titles = {
      titleColumn1: "Total Order Quantity",
      titleColumn2: "Issued & Pending Quantity",
    };
    const currentDate = DateHelper.toIsoDateFormat(new Date());
    const remainingTotal = totalQuantity - issuedQuantity - pendingQuantity;
    return (
      <div className="details row flex-column-reverse flex-xl-row">
        <div className="col-xl-6">
          <RowFormField label="Order ID:" htmlFor="OrderId">
            <input
              type="text"
              id="OrderId"
              className="form-control display-input"
              value={orderId}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Total Order Quantity:" htmlFor="TotalQuantity">
            <input
              type="number"
              readOnly
              id="TotalQuantity"
              value={totalQuantity}
            />
          </RowFormField>
          {eVoucherProgram && (
            <EvoucherProgramPreview evoucherProgramId={eVoucherProgram?.id} />
          )}
          <RowFormField
            label={
              <span>
                Batch Quantity<span className="color-red">*</span>:
              </span>
            }
            htmlFor="BatchQuantity"
          >
            <div className="row">
              <div className="col">
                <IntegerInput
                  className="form-control display-input"
                  type="number"
                  max={remainingTotal}
                  min={1}
                  placeholder="Enter Batch Quantity"
                  fieldname="totalQuantity"
                  value={model.totalQuantity}
                  onChange={this.onTextBoxChange}
                  onKeyDown={preventNonNumericInput}
                />
              </div>
              <label className="form-control-label col-2 col-md-3 col-xl-2 m-0 p-0 d-flex align-items-center">{`/ ${remainingTotal}`}</label>
            </div>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="totalQuantity"
              isEnabled={isSubmitted}
              property="Batch Quantity"
              value={model.totalQuantity}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="totalQuantity"
              isEnabled={isSubmitted}
              property="Batch Quantity"
              value={model.totalQuantity}
              max={remainingTotal}
              min={1}
              allowDecimal={false}
            />
          </RowFormField>
          <RowFormField
            label={
              <span>
                Validity Start<span className="color-red">*</span>:
              </span>
            }
            htmlFor="ValidityStart"
          >
            <input
              className="form-control display-input"
              type="datetime-local"
              placeholder="Enter Validity Start"
              value={model.validityStart}
              fieldname="validityStart"
              id="validityStart"
              onChange={this.onTextBoxChange}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="validityStart"
              isEnabled={isSubmitted}
              fromProperty="Validity Start"
              fromValue={currentDate}
              toProperty="Current Date"
              toValue={model.validityStart}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="validityStart"
              isEnabled={isSubmitted}
              property="Validity Start"
              value={model.validityStart}
            />
          </RowFormField>
          <RowFormField
            label={
              <span>
                Expiry Date<span className="color-red">*</span>:
              </span>
            }
            htmlFor="ExpiryDate"
          >
            <input
              className="form-control display-input"
              type="datetime-local"
              id="expiryDate"
              placeholder="Enter Validity End"
              fieldname="expiryDate"
              value={model.expiryDate}
              onChange={this.onTextBoxChange}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="expiryDate"
              isEnabled={isSubmitted}
              property="Expiry Date"
              value={model.expiryDate}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="expiryDate"
              isEnabled={isSubmitted}
              fromProperty="Validity Start"
              fromValue={model.validityStart}
              toProperty="Expiry Date"
              toValue={model.expiryDate}
            />
          </RowFormField>
        </div>
        {eVoucherProgram && (
          <EvoucherQuantityPreview
            imageLink={eVoucherProgram.urlImage}
            quantities={quantities}
            titles={titles}
          />
        )}
      </div>
    );
  }
}
