import { downloadFile, showLoading } from "../../../common/Common";
import React from "react";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelEvoucherBatchDialog } from "./dialogs/CancelEvoucherBatchDialog";
import { ExtendBatchValidityDialog } from "./dialogs/ExtendBatchValidityDialog";
import { EvoucherBatchInformationCard } from "./cards/EvoucherBatchInformationCard";
import {
  EVoucherBatchExtensionRequestStatus,
  EVoucherBatchStatus,
  GenericMessages,
  ObjectType,
} from "../../../common/Constants";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { EvoucherBatchMode } from "../common/EvoucherBatchMode";
import { EVoucherHistoryDialog } from "../../common/EVoucherHistoryDialog";

import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { EVoucherBatchRestService } from "../../../services/EVoucherBatchRestService";
import { FileDownloadModal } from "./dialogs/FileDownloadModal";
import { AuthService } from "../../../services/AuthService";

export class EvoucherBatchDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherBatchOrder: null,
      evoucherBatchOrderId: "",
      isShowConfirmApproveDialog: false,
      isShowConfirmRejectDialog: false,
      isShowExtendValidityDialog: false,
      isShowConfirmCancelDialog: false,
      showEVoucherBatchHistoryDialog: false,
      isExtendedValidity: false,
      evoucherExtensionBatch: null,
      isSubmitted: false,
      extensionRequestQuantity: 0,
      evoucherBatchValidations: [],
      isShow2FADialog: false,
    };
    this.eVoucherBatchRestService = new EVoucherBatchRestService();
    this.authService = new AuthService();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherBatchOrderId = StringHelper.toNumber(id, 0);
    if (evoucherBatchOrderId > 0) {
      this.setState({ evoucherBatchOrderId }, this.refreshData);
    }
  }

  isValidated() {
    const { evoucherBatchValidations } = this.state;
    return evoucherBatchValidations.every((val) => val.isValid);
  }

  refreshData() {
    const { id } = this.props.match.params;
    RestClient.sendGetRequest(
      `/api/v1/evoucher_batches/${id}`,
      (response) => {
        this.setState({ evoucherBatchOrder: response });
        this.loadPendingExtensionRequests(response.reference);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  toggleConfirmApproveDialog = (visible) => {
    this.setState({ isShowConfirmApproveDialog: visible });
  };

  toggleConfirmRejectDialog = (visible) => {
    this.setState({ isShowConfirmRejectDialog: visible });
  };

  toggleExtendValidityDialog = (visible) => {
    this.setState({ isShowExtendValidityDialog: visible });
  };

  toggleConfirmCancelDialog = (visible) => {
    this.setState({ isShowConfirmCancelDialog: visible });
  };

  toggle2FADialog = (visible) => {
    this.setState({ isShow2FADialog: visible });
  };

  onReject = async (reason) => {
    const { evoucherBatchOrder } = this.state;
    const response = await this.eVoucherBatchRestService.reject(
      evoucherBatchOrder.id,
      {
        reason,
      }
    );
    this.toggleConfirmRejectDialog(false);
    if (response.status === 200) {
      const { data: eVoucherBatch } = response;
      this.setState({
        evoucherBatchOrder: {
          ...this.state.evoucherBatchOrder,
          approvalReason: eVoucherBatch.approvalReason,
          status: eVoucherBatch.status,
        },
      });
      toast.success(GenericMessages.EVoucherBatchRejectionMessage);
    }
  };

  onCancel = async (reason) => {
    const { evoucherBatchOrder } = this.state;
    const response = await this.eVoucherBatchRestService.cancel(
      evoucherBatchOrder.id,
      {
        reason,
      }
    );
    this.toggleConfirmRejectDialog(false);
    if (response.status === 200) {
      const { data: eVoucherBatch } = response;
      this.setState({
        evoucherBatchOrder: {
          ...this.state.evoucherBatchOrder,
          approvalReason: eVoucherBatch.approvalReason,
          status: eVoucherBatch.status,
        },
      });
      this.toggleConfirmCancelDialog(false);
      toast.success(GenericMessages.EVoucherBatchCancellationMessage);
    }
  };

  onApprove = async () => {
    const { evoucherBatchOrderId } = this.state;
    const response = await this.eVoucherBatchRestService.approve(
      evoucherBatchOrderId
    );
    this.toggleConfirmApproveDialog(false);
    if (response.status === 200) {
      const { data: eVoucherBatch } = response;
      this.setState({
        evoucherBatchOrder: {
          ...this.state.evoucherBatchOrder,
          usedQuantity: eVoucherBatch.usedQuantity,
          status: eVoucherBatch.status,
        },
      });

      toast.success(GenericMessages.EVoucherBatchApprovalMessage);
    }
  };

  downloadEVouchers = async (password) => {
    const isValid2FA = await this.validateUserPassword(password);
    if (!isValid2FA) return;
    showLoading(true);
    this.toggle2FADialog(false);
    const response = await this.eVoucherBatchRestService.download(
      this.state.evoucherBatchOrder.id
    );
    showLoading(false);
    if (response.status === 200) {
      downloadFile(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      toast.success(GenericMessages.EVoucherBatchDownloadMessage);
    }
  };

  validateUserPassword = async (password) => {
    showLoading(true);
    const username = AuthenticationManager.username();
    const res = await this.authService.post({ username, password });
    showLoading(false);
    return res.status === 200;
  };

  loadPendingExtensionRequests = (eVoucherBatchReference) => {
    const queryParameter = {
      eVoucherBatchReference,
      status: EVoucherBatchExtensionRequestStatus.Pending,
    };
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_batch_extension_requests/`,
      queryParameter,
      (response) => {
        if (response?.data?.length > 0) {
          this.setState({
            isExtendedValidity: true,
            evoucherExtensionBatch: response.data[0],
          });
        }
      }
    );
  };

  onExtend = (extendRequest) => {
    if (!this.isValidated()) {
      this.setState({ isSubmitted: true });
      return;
    }
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batch_extension_requests/`,
      extendRequest,
      (response) => {
        this.setState({
          isExtendedValidity: true,
          evoucherExtensionBatch: response,
        });
        this.toggleExtendValidityDialog(false);
        toast.success(
          "eVoucher Batch Extension Request has been created successfully."
        );
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-details",
        name: "eVoucher Batch Details",
      }),
    });
  };

  render() {
    const {
      isSubmitted,
      evoucherBatchOrder,
      isShowConfirmApproveDialog,
      isShowConfirmRejectDialog,
      isShowExtendValidityDialog,
      isShowConfirmCancelDialog,
      isExtendedValidity,
      showEVoucherBatchHistoryDialog,
      isShow2FADialog,
    } = this.state;

    const isOwner =
      evoucherBatchOrder &&
      evoucherBatchOrder.createdBy === AuthenticationManager.username();

    const isCancellable =
      isOwner ||
      AuthenticationManager.isAuthorized(
        ResourceID.EVoucherViewBatchDetailsPageCancelBatchButton
      );
    const isDownloadable =
      evoucherBatchOrder &&
      evoucherBatchOrder.status === EVoucherBatchStatus.Approved &&
      (isOwner ||
        AuthenticationManager.isAuthorized(
          ResourceID.EVoucherViewBatchDetailsDownloadButton
        ));

    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              {evoucherBatchOrder && (
                <>
                  {isDownloadable && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={() => this.toggle2FADialog(true)}
                    >
                      Download eVoucher Batch Codes
                    </ButtonActionDetailGroup.Item>
                  )}
                  {(evoucherBatchOrder.status ===
                    EVoucherBatchStatus.Approved ||
                    evoucherBatchOrder.status ===
                      EVoucherBatchStatus.Expired) &&
                    !isExtendedValidity &&
                    AuthenticationManager.isAuthorized(
                      ResourceID.EvoucherViewBatchDetailsExtendValidityButton
                    ) &&
                    isOwner && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-primary"
                        onClick={() => this.toggleExtendValidityDialog(true)}
                      >
                        Extend Validity
                      </ButtonActionDetailGroup.Item>
                    )}
                </>
              )}
              <ButtonActionDetailGroup.Item
                id="batchHistory"
                className="btn btn-outline-secondary"
                onClick={() =>
                  this.setState({ showEVoucherBatchHistoryDialog: true })
                }
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col-md-12">
                {evoucherBatchOrder && (
                  <EvoucherBatchInformationCard
                    cardMode={EvoucherBatchMode.View}
                    evoucherBatchOrder={evoucherBatchOrder}
                    evoucherOrderId={evoucherBatchOrder.orderId}
                  />
                )}
              </div>
            </div>
            {evoucherBatchOrder &&
              evoucherBatchOrder.status === EVoucherBatchStatus.Pending && (
                <ButtonActionDetailGroup>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.EVoucherViewBatchDetailsPageApproveRejectButton
                  ) &&
                    !isOwner && (
                      <>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-success"
                          id="btnApprove"
                          onClick={() => this.toggleConfirmApproveDialog(true)}
                        >
                          Approve
                        </ButtonActionDetailGroup.Item>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-danger"
                          id="btnReject"
                          onClick={() => this.toggleConfirmRejectDialog(true)}
                        >
                          Reject
                        </ButtonActionDetailGroup.Item>
                      </>
                    )}
                  {isCancellable && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-secondary"
                      id="btnCancel"
                      onClick={() => this.toggleConfirmCancelDialog(true)}
                    >
                      Cancel Batch
                    </ButtonActionDetailGroup.Item>
                  )}
                </ButtonActionDetailGroup>
              )}
          </div>
        </div>
        <ConfirmDialog
          visible={isShowConfirmApproveDialog}
          onCancel={() => this.toggleConfirmApproveDialog(false)}
          onProceed={this.onApprove}
          message="Are you sure you want to approve eVoucher Batch Order?"
          title="Approve eVoucher Batch Order"
        />
        {isShowConfirmRejectDialog && (
          <CancelEvoucherBatchDialog
            onCancel={() => this.toggleConfirmRejectDialog(false)}
            onProceed={this.onReject}
            message="Are you sure you want to reject eVoucher Batch Order?"
            title="Reject eVoucher Batch Order"
            placeHolder="Enter reason of rejecting"
          />
        )}
        {isShowConfirmCancelDialog && (
          <CancelEvoucherBatchDialog
            onCancel={() => this.toggleConfirmCancelDialog(false)}
            onProceed={this.onCancel}
            message="Are you sure you want to cancel eVoucher Batch Order?"
            title="Cancel eVoucher Batch Order"
          />
        )}
        {isShowExtendValidityDialog && (
          <ExtendBatchValidityDialog
            currentExpiredDate={evoucherBatchOrder.validityEnd}
            isSubmitted={isSubmitted}
            eVoucherBatchId={evoucherBatchOrder.id}
            onCancel={() => this.toggleExtendValidityDialog(false)}
            onProceed={this.onExtend}
          />
        )}
        {showEVoucherBatchHistoryDialog && (
          <EVoucherHistoryDialog
            objectId={evoucherBatchOrder.id}
            onClose={() =>
              this.setState({ showEVoucherBatchHistoryDialog: false })
            }
            url="/api/v1/history_logs"
            objectType={ObjectType.EvoucherBatch}
            getStatusColor={EVoucherBatchStatus.getColor}
          />
        )}
        {isShow2FADialog && (
          <FileDownloadModal
            onSubmit={(password) => this.downloadEVouchers(password)}
            onCancel={() => this.toggle2FADialog(false)}
            title={"Input your Account Password"}
          />
        )}
      </div>
    );
  }
}
