import React, { Component } from "react";
import { CSVLink } from "react-csv";
import RestClient from "../../../common/RestClient";
import { generateRandomStringWithDate, Strings } from "../../../common/Common";
import { BreadCrumb } from "../../common/BreadCrumb";
import { DateHelper } from "../../../common/Helpers";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { HistoryDialog } from "../../common/HistoryDialog";
import { HistoryMessageTypes, ObjectType } from "../../../common/Constants";
import { CheckboxInput } from "../../campaigns/CheckboxInput";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class HallTransactionEnquiry extends Component {
  constructor(props) {
    super(props);
    this.hallTypes = {
      all: 0,
      multiple: 1,
    };

    this.state = {
      data: [],
      from: undefined,
      to: undefined,
      locations: [],
      locationVenues: [],
      selectedLocationId: 0,
      selectedLocationVenueIds: [],
      selectedHallType: this.hallTypes.all,
      isLoading: false,
      showHistoryDialog: false,
      includeHistorical: false,
    };
    this.csvLink = React.createRef();
  }

  getErrorMessage() {
    const { from, to, selectedLocationId } = this.state;
    if (Strings.isNullOrEmpty(from)) {
      return "Performance Start Time is required.";
    }

    if (Strings.isNullOrEmpty(to)) {
      return "Performance Start End is required.";
    }

    if (!selectedLocationId || selectedLocationId === 0) {
      return "Location is required.";
    }
    return "";
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "transactions",
        name: "Transaction Management",
      }),
      activeModule: new NavigationItem({
        identifier: "hall_transaction_enquiry",
        name: "Hall Transaction Enquiry",
      }),
    });
  };

  getReport = async () => {
    const {
      selectedLocationVenueIds,
      selectedLocationId,
      from,
      to,
      includeHistorical,
    } = this.state;
    const errorMsg = this.getErrorMessage();
    if (!Strings.isNullOrEmpty(errorMsg)) {
      toast.error(errorMsg);
      return;
    }
    const fromDate = DateHelper.toDisplayFullDateFormat(from).replace(",", "");
    const toDate = DateHelper.toDisplayFullDateFormat(to).replace(",", "");
    const timeFrame = `${fromDate},${toDate}`;
    this.setState({ isLoading: true });
    await RestClient.sendGetRequestWithParameters(
      "/api/v1/transactions/reports",
      {
        timeFrame: timeFrame,
        locationId: selectedLocationId,
        locationVenueIds: selectedLocationVenueIds.join(","),
        includeHistorical,
      },
      (response) => {
        toast.success("The csv file are downloading. Please wait a second.");
        this.setState({ data: response, isLoading: false }, () => {
          setTimeout(() => this.csvLink.current.link.click(), 500);
        });
      },
      (error) => {
        toast.error(error.message);
        this.setState({ isLoading: false });
      }
    );
  };

  onSelectLocation = (e) => {
    const id = +e.target.value;
    if (isNaN(id) || id === 0) {
      return;
    }
    this.setState({ isLoading: true });
    RestClient.sendGetRequestWithParameters(
      "/api/v1/locations/venues",
      {
        locationId: id,
      },
      (response) => {
        this.setState({
          locationVenues: response.data,
          isLoading: false,
        });
      },
      (error) => {
        toast.error(error.message);
        this.setState({ isLoading: false });
      }
    );
    this.setState({
      selectedLocationId: id,
      selectedHallType: this.hallTypes.all,
    });
  };

  onSelectType = (e) => {
    this.setState({ selectedHallType: +e.target.value });
  };

  onCheckIncludeHistorical = (e) => {
    this.setState({ includeHistorical: e.target.checked });
  };

  onSelectLocationVenues = (e) => {
    let { selectedLocationVenueIds } = this.state;
    const locationVenueId = +e.target.value;
    if (!e.target.checked) {
      selectedLocationVenueIds = selectedLocationVenueIds.filter(
        (x) => x !== locationVenueId
      );
    } else {
      selectedLocationVenueIds.push(locationVenueId);
    }
    this.setState({ selectedLocationVenueIds });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await RestClient.sendGetRequest(
      "/api/v1/locations",
      (response) => {
        this.setState({ locations: response.data, isLoading: false });
      },
      (error) => {
        toast.error(error.message);
        this.setState({ isLoading: false });
      }
    );
  }

  formulateData = (data) => {
    const result = [];
    result.push([
      "TransactionReferenceNumber",
      "OrderSystemComponentCode",
      "CustomerName",
      "CustomerContactNumber",
      "CustomerEmailAddress",
      "CardReference",
      "CSOName",
      "POSName",
      "TransactionAmount",
      "TransactionDateTime",
      "TransactionType",
      "PerformanceActualDateTime",
      "MovieTitle",
      "Day",
      "Date",
      "Time",
      "TicketType",
      "SeatNumber",
      "HallName",
      "Status",
    ]);
    if (!data || !Array.isArray(data)) return result;

    data.forEach((item) => {
      const arrayItem = [];
      const transaction = item.transaction;
      arrayItem.push(transaction.referenceNumber);
      arrayItem.push(transaction.system);
      arrayItem.push(transaction.customerName);
      arrayItem.push(transaction.customerContact);
      arrayItem.push(transaction.customerEmail);
      arrayItem.push(transaction.cardReference);
      arrayItem.push(transaction.csoName);
      arrayItem.push(transaction.posName);
      arrayItem.push(transaction.transactionAmount);
      arrayItem.push(transaction.transactionDateTime);
      arrayItem.push(transaction.transactionType);

      const ticket = item.ticket;
      arrayItem.push(ticket.performanceActualDateTime);
      arrayItem.push(ticket.movieTitle);
      arrayItem.push(ticket.day);
      arrayItem.push(ticket.date);
      arrayItem.push(ticket.time);
      arrayItem.push(ticket.ticketType);
      arrayItem.push(ticket.seatNumber);
      arrayItem.push(ticket.hallName);

      arrayItem.push(transaction.status);

      result.push(arrayItem);
    });
    return result;
  };

  onViewHistoryClick = () => {
    this.setState({ showHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showHistoryDialog: false });
  };

  render() {
    const {
      data,
      showHistoryDialog,
      from,
      to,
      locations,
      selectedLocationId,
      selectedHallType,
      includeHistorical,
      locationVenues,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                data-toggle="modal"
                data-target="#UserHistory"
                onClick={this.onViewHistoryClick}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col-md-5">
                <div className="card">
                  <div className="card-body card-block">
                    <div className="row form-group">
                      <div className="col-12">
                        <label
                          className="form-control-label"
                          htmlFor="PerformanceStartDateTime"
                        >
                          Select Showtime Start Time
                        </label>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          id="PerformanceStartDateTime"
                          type="datetime-local"
                          onChange={(e) =>
                            this.setState({ from: e.target.value })
                          }
                          value={from ?? ""}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-12">
                        <label
                          className="form-control-label"
                          htmlFor="PerformanceEndDateTime"
                        >
                          Select Showtime End Time
                        </label>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          id="PerformanceEndDateTime"
                          type="datetime-local"
                          onChange={(e) =>
                            this.setState({ to: e.target.value })
                          }
                          value={to ?? ""}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-12">
                        <label
                          className="form-control-label row col-12"
                          htmlFor="Location"
                        >
                          Location
                        </label>
                      </div>
                      <div className="col">
                        <select
                          className="form-control"
                          id="Location"
                          value={selectedLocationId}
                          onChange={this.onSelectLocation}
                        >
                          <option value={0}>Select a location</option>
                          {locations.map((e) => (
                            <option value={e.id} key={e.id}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-12">
                        <label
                          className="form-control-label"
                          htmlFor="LocationVenue"
                        >
                          Location Venue
                        </label>
                      </div>
                      <div className="col">
                        <select
                          className="form-control"
                          id="LocationVenue"
                          onChange={this.onSelectType}
                          value={selectedHallType}
                        >
                          <option value={this.hallTypes.all}>All Hall</option>
                          <option value={this.hallTypes.multiple}>
                            Multiple Halls
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row ml-3 mb-3">
                      <div
                        className="form-check"
                        style={
                          selectedHallType === this.hallTypes.multiple
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {locationVenues.map((item) => (
                          <div className="checkbox" key={item.id}>
                            <label
                              htmlFor="Pending"
                              className="form-check-label mr-2"
                            >
                              <input
                                type="checkbox"
                                value={item.id}
                                className="mr-1"
                                onChange={this.onSelectLocationVenues}
                              />
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="pl-3">
                        <label
                          className="form-control-label"
                          htmlFor="LocationVenue"
                        >
                          Include Historical
                        </label>
                      </div>
                      <div className="col-3">
                        <CheckboxInput
                          onChange={this.onCheckIncludeHistorical}
                          value={includeHistorical}
                        />
                      </div>
                    </div>
                    <ButtonActionDetailGroup>
                      <ButtonActionDetailGroup.Item
                        className="btn btn-primary"
                        onClick={this.getReport}
                        disabled={isLoading}
                      >
                        <i className="fas fa-chart-bar" />
                        &nbsp;Request Report
                      </ButtonActionDetailGroup.Item>
                    </ButtonActionDetailGroup>
                    <CSVLink
                      data={this.formulateData(data)}
                      filename={`${generateRandomStringWithDate()}.csv`}
                      ref={this.csvLink}
                      target="_blank"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showHistoryDialog && (
          <HistoryDialog
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.Transaction}
            messageId={HistoryMessageTypes.HallTransactionDownloaded}
          />
        )}
      </div>
    );
  }
}
