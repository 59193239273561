import React, { useEffect, useState } from "react";
import { DateHelper } from "../../../../common/Helpers";
import { PageSizeSelector, Paginator } from "../../../../common/Paginator";
import RestClient from "../../../../common/RestClient";

const EVOUCHER_ACTIONS = {
  MultipleDownloading: 13102,
  MultipleBlocking: 13100,
  MultipleUnblocking: 13101,
  MultipleExtension: 13103,
};

export const EVoucherActionsHistoryModal = (props) => {
  const { onClose } = props;

  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [logDetails, setLogDetails] = useState(null);

  const showDetails = (item) => {
    const detail = {
      message: item.message,
      eVouchers: JSON.parse(item.newValue),
    };
    setLogDetails(detail);
    setShowDetail(true);
  };

  useEffect(() => {
    const searchHistory = async (page) => {
      const request = {
        maxResults: 10,
        startAt: (page - 1) * 10,
        sortColumn:'-CreatedOn',
        messageIds: [
          EVOUCHER_ACTIONS.MultipleDownloading,
          EVOUCHER_ACTIONS.MultipleBlocking,
          EVOUCHER_ACTIONS.MultipleUnblocking,
          EVOUCHER_ACTIONS.MultipleExtension,
        ],
      };
      RestClient.sendGetRequestWithParameters(
        "/api/v1/history_logs",
        request,
        (response) => {
          setData(response.data);
          setTotal(response.total)
        },
        (error) => {
          console.log(error);
        }
      );
    };

    searchHistory(currentPage);
  }, [currentPage]);

  const renderHistoryDetailTableBody = () => {
    return (
      <tbody>
        <tr>
          <td style={{ whiteSpace: "unset" }}>
            {logDetails.eVouchers.map((x) => x.code).join(", ")}
          </td>
        </tr>
      </tbody>
    );
  };

  const onPageIndexChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                EVoucher History
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="table-responsive m-b-20 table-px-15 modal-max-height">
                    {!showDetail && (
                      <table
                        className="table table-borderless table-striped table-earning"
                        style={{ tableLayout: "auto" }}
                      >
                        <thead>
                          <tr>
                            <th>Action Changes</th>
                            <th>Action By</th>
                            <th>Action Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item) => (
                            <tr key={item.id}>
                              <td>
                                {item.message}{" "}
                                {!item.isConfidential && (
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showDetails(item);
                                    }}
                                  >
                                    view details
                                  </a>
                                )}
                              </td>
                              <td>{item.createdBy}</td>
                              <td>
                                {DateHelper.toDisplayDateTimeFormat(
                                  item.createdOn
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {showDetail && (
                      <div>
                        <h5 className="mb-2">{logDetails.message}</h5>
                        <table
                          className="table table-borderless table-striped table-earning"
                          style={{ tableLayout: "auto", textAlign: "center" }}
                        >
                          <thead>
                            <tr>
                              <th>eVoucher Code</th>
                            </tr>
                          </thead>
                          {renderHistoryDetailTableBody()}
                        </table>
                      </div>
                    )}
                  </div>
                  {showDetail && (
                    <div
                      style={{
                        textAlign: "right",
                        alignSelf: "stretch",
                        width: "100%",
                      }}
                    >
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowDetail(false);
                          setLogDetails(null);
                        }}
                      >
                        Back
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {!showDetail && (
                <div className="d-flex justify-content-between">
                  <Paginator
                    onPageIndexChange={onPageIndexChange}
                    pageIndex={currentPage}
                    pageSize={10}
                    total={total}
                  />
                  <div>
                    <PageSizeSelector pageSize={10} selectors={[10]} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
