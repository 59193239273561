import React from "react";
import { Card } from "../../../common/Card";
import { EVoucherStatus, ApplicationID } from "../../../../common/Constants";
import { DateHelper } from "../../../../common/Helpers";
import { EvoucherProgramPreview } from "../../common/EvoucherProgramPreview";
import { EvoucherQuantityPreview } from "../../common/EvoucherQuantityPreview";
import RestClient from "../../../../common/RestClient";
import { YesNoOptions } from "../../../../common/Constants";
import { toast } from "react-toastify";
import { RowFormField } from "../../../layout/FormLayout";
import { EVoucherBatchRestService } from "../../../../services/EVoucherBatchRestService";

export class EvoucherInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eVoucher: props.eVoucher,
      eVoucherOrder: null,
      evoucherBatch: null,
    };
    this.eVoucherBatchRestService = new EVoucherBatchRestService();
  }

  async componentDidMount() {
    const { eVoucher } = this.state;
    if (eVoucher.salesChannel === ApplicationID.INCP) {
      this.loadEVoucherOrderById();
    }
    await this.loadEVoucherBatchById();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eVoucher.id !== this.props.eVoucher.id ||
      prevProps.eVoucher.status !== this.props.eVoucher.status
    ) {
      const { eVoucher } = this.props;
      this.setState({ eVoucher: eVoucher });
      if (eVoucher.salesChannel === ApplicationID.INCP) {
        this.loadEVoucherOrderById();
      }
    }
  }

  loadEVoucherOrderById = () => {
    const parameters = { orderId: this.state.eVoucher.orderId };
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_orders/`,
      parameters,
      (response) => {
        if (response?.data?.length > 0) {
          this.setState({ eVoucherOrder: response.data[0] });
        }
      },
      (error) => toast.error(error.message)
    );
  };

  loadEVoucherBatchById = async () => {
    const response = await this.eVoucherBatchRestService.get(
      this.props.eVoucher.eVoucherBatchId
    );
    if (response.status === 200) {
      this.setState({ evoucherBatch: response.data });
    }
  };

  renderBoolean(value) {
    return value
      ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
      : YesNoOptions.getYesNoOptions(YesNoOptions.No);
  }

  renderViewMode() {
    const { eVoucher, eVoucherOrder, evoucherBatch } = this.state;
    return (
      <div className="details row  flex-column-reverse flex-xl-row">
        <div className="col-xl-7">
          <RowFormField label="eVoucher Code:" htmlFor="eVoucherCode">
            <input
              type="text"
              id="eVoucherCode"
              className="form-control display-input"
              value={eVoucher.code}
              readOnly
            />
          </RowFormField>
          <EvoucherProgramPreview
            evoucherProgramId={eVoucher.eVoucherProgram?.id}
          />
          <RowFormField label="Batch ID:" htmlFor="batchId">
            <input
              className="form-control display-input"
              fieldname="batchId"
              value={evoucherBatch?.reference}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Status:" htmlFor="status">
            <input
              className={EVoucherStatus.getColor(eVoucher.status)}
              fieldname="status"
              value={eVoucher.status}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Is Refund Voucher:" htmlFor="isRefundVoucher">
            <input
              className="form-control display-input"
              id="isRefundVoucher"
              fieldname="isRefundVoucher"
              value={this.renderBoolean(eVoucher.isRefundEVoucher)}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Validity Start:" htmlFor="validityStart">
            <input
              className="form-control display-input"
              value={DateHelper.toDisplayIsoDateTime(eVoucher.validityStart)}
              fieldname="validityStart"
              id="validityStart"
              readOnly
            />
          </RowFormField>
          <RowFormField label="Expiry Date:" htmlFor="expiryDate">
            <input
              className="form-control display-input"
              id="expiryDate"
              fieldname="expiryDate"
              value={DateHelper.toDisplayIsoDateTime(eVoucher.validityEnd)}
              readOnly
            />
          </RowFormField>
          {eVoucher.status === EVoucherStatus.Blocked && (
            <RowFormField label="Reason for block:" htmlFor="approvalReason">
              <input
                className="form-control display-input"
                id="approvalReason"
                fieldname="approvalReason"
                value={eVoucher.approvalReason}
                readOnly
              />
            </RowFormField>
          )}
          <RowFormField label="Remaining Duration:" htmlFor="remainingDuration">
            <input
              className="form-control display-input"
              id="remainingDuration"
              fieldname="remainingDuration"
              value={Math.ceil(eVoucher.remainingDuration) + " days"}
              readOnly
            />
          </RowFormField>
          {eVoucher.salesChannel === ApplicationID.INCP && eVoucherOrder && (
            <>
              <RowFormField label="Company Name:" htmlFor="companyName">
                <input
                  className="form-control display-input"
                  id="companyName"
                  fieldname="companyName"
                  value={eVoucherOrder.companyName}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Created By:" htmlFor="createdBy">
                <input
                  className="form-control display-input"
                  id="createdBy"
                  fieldname="createdBy"
                  value={eVoucherOrder.createdBy}
                  readOnly
                />
              </RowFormField>
              <RowFormField
                label="Purchase Order Number:"
                htmlFor="purchaseOrderNumber"
              >
                <input
                  className="form-control display-input"
                  id="purchaseOrderNumber"
                  fieldname="purchaseOrderNumber"
                  value={eVoucherOrder.purchaseOrderNumber}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Memo No.:" htmlFor="memoNumber">
                <input
                  className="form-control display-input"
                  id="memoNumber"
                  fieldname="memoNumber"
                  value={eVoucherOrder.memoNumber}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Payment Terms:" htmlFor="paymentTerms">
                <label className="col-3 form-control-label"></label>
                <div className="col-8">
                  <input
                    className="form-control display-input"
                    id="paymentTerms"
                    fieldname="paymentTerms"
                    value={eVoucherOrder.paymentTerms}
                    readOnly
                  />
                </div>
              </RowFormField>
            </>
          )}
          {eVoucher.salesChannel !== ApplicationID.INCP && (
            <>
              <RowFormField
                label="Sales Transaction Id:"
                htmlFor="salesTransactionId"
              >
                <input
                  className="form-control display-input"
                  id="salesTransactionId"
                  fieldname="salesTransactionId"
                  value={eVoucher.salesTransactionId}
                  readOnly
                />
              </RowFormField>
            </>
          )}
        </div>
        <EvoucherQuantityPreview
          imageLink={eVoucher.eVoucherProgram?.urlImage}
        />
      </div>
    );
  }

  render() {
    return <Card title="eVoucher Details">{this.renderViewMode()}</Card>;
  }
}
