import React from "react";
import { TypeProceedRequest } from "../../../../common/Constants";

export class RejectedEvoucherExtensionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      controlClassName: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.getControlClassName();
  }

  getControlClassName(reason) {
    const { typeProceed } = this.props;
    const isError = reason?.length < 4;
    return isError && typeProceed === TypeProceedRequest.Reject
      ? "form-control is-invalid"
      : "form-control";
  }

  onSubmitClick = () => {
    const { reason } = this.state;
    const { typeProceed } = this.props;
    const isErrorReject = reason.length < 4;
    const isError = reason.length === 0;
    if (isErrorReject && typeProceed === TypeProceedRequest.Reject) {
      this.setState({
        errorMessage: "The reason reject must be least 4 characters",
      });
      return;
    }

    if (isError) {
      this.setState({
        errorMessage: "The reason cancel must be greater than 0 characters",
      });
      return;
    }

    if (this.props.onProceed) {
      this.props.onProceed(reason, this.props.typeProceed);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    this.setState({
      [fieldName]: e.target.value,
      controlClassName: this.getControlClassName(e.target.value),
      errorMessage: "",
    });
  };

  render() {
    let { message, title, placeholderText } = this.props;
    const { reason, errorMessage } = this.state;

    message = message || "Are you sure you want to reject the request?";
    title = title || "Confirmation";
    placeholderText = placeholderText || "Enter reason for rejecting request";

    return (
      <React.Fragment>
        <div
          className="modal fade show"
          id="confirmRejectModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-control-label" htmlFor="CompName">
                        {message}
                      </label>
                    </div>
                  </div>
                  <div className="row pb-2 pt-3">
                    <div className="col-md-4">
                      <label className="form-control-label">Reason:</label>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        id="Reason"
                        rows="4"
                        placeholder={placeholderText}
                        className={
                          this.state.controlClassName ||
                          "form-control is-invalid"
                        }
                        fieldname="reason"
                        onChange={this.onTextBoxChange}
                        defaultValue={reason}
                      />
                      <label className="status--denied">{errorMessage}</label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onSubmitClick}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.props.onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </React.Fragment>
    );
  }
}
