import { atom, selector } from "recoil";
import { CorporateBookingModel } from "./booking/models/CorporateBookingCreationRequest";
import _ from "lodash";

export const corporateBookingAtom = atom({
  key: "corporateBooking",
  default: new CorporateBookingModel({}),
});

export const corporateBookingDetailSelector = selector({
  key: "corporateBookingDetailSelector",
  get: ({ get }) => {
    return get(corporateBookingAtom).corporateBookingDetails;
  },
});

export const corporateBookingSeatCodesSelector = selector({
  key: "corporateBookingSeatCodesSelector",
  get: ({ get }) => {
    return get(corporateBookingAtom).corporateBookingDetails?.seatCodes ?? [];
  },
  set: ({ set, get }, newValue) => {
    const corporateBooking = _.cloneDeep(get(corporateBookingAtom));
    corporateBooking.corporateBookingDetails.seatCodes = newValue;
    set(corporateBookingAtom, corporateBooking);
  },
});
