import React, { useMemo } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { MovieAdviceService } from "../../services/MovieAdviceService";

const AdviceSelect = ({ onChange, selectedValue }) => {
  const adviceService = useMemo(() => new MovieAdviceService(), []);
  const search = async (name = "") => {
    const params = {
      name,
      maxResults: 999,
      startAt: 0,
    };
    return (await adviceService.list(params)).data;
  };

  const loadOptionHandle = (name, callback) => {
    (async () => {
      const { data } = await search(name);
      const options = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      callback(options);
    })();
  };

  const create = async (name) => {
    const { data } = await adviceService.post({
      name,
      label: name,
    });
    return { label: data.name, value: data.id };
  };

  const onSelect = async (value, actionMeta) => {
    let data = { ...value };
    if (actionMeta.action === "create-option") {
      data = await create(value.label);
    }
    onChange(data);
  };

  return (
    <AsyncCreatableSelect
      isClearable
      defaultOptions={true}
      loadOptions={loadOptionHandle}
      onChange={onSelect}
      value={selectedValue}
    />
  );
};

export default AdviceSelect;
