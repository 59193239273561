import React from "react";
import { DateHelper } from "../../common/Helpers";
import { PageSizeSelector, Paginator } from "../../common/Paginator";
import RestClient from "../../common/RestClient";
import { toast } from "react-toastify";
import { Constants } from "./Constant";
import { HallTransactionEnquiryKeys } from "../../constants/TransactionConstants";

export class HistoryDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      showDetail: false,
      currentLocation: null,
      message: null,
      title: "History",
      locations: [],
    };
    this.pageBound = Constants.MaxNumberOfPagination;
    this.upperPageBound = Constants.MaxNumberOfPagination;
    this.lowerPageBound = 0;
  }

  getLocationOptions = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/locations`,
      { includes: "LocationVenues" },
      (response) => {
        this.setState({ locations: response.data });
      },
      (error) => {
        toast(error);
      }
    );
  };

  getDefaultSort = () => "-CreatedOn";

  injectSortTerm(queryParameters, sortBy) {
    if (sortBy != null) {
      queryParameters.sortColumn = sortBy;
      this.sortBy = sortBy;
    } else {
      queryParameters.sortColumn = this.sortBy
        ? this.sortBy
        : this.getDefaultSort();
    }
    return queryParameters;
  }

  componentDidMount() {
    this.refreshData();
    this.getLocationOptions();
  }

  onPageIndexChange = (pageIndex) => {
    this.setState({ pageIndex }, this.refreshData);
  };

  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize }, this.refreshData);
  };

  refreshData = () => {
    const {
      objectId,
      objectType,
      parentObjectId,
      parentObjectType,
      messageId,
    } = this.props;
    const { pageIndex, pageSize } = this.state;
    let request = {
      maxResults: pageSize,
      startAt: (pageIndex - 1) * pageSize,
    };
    request = this.injectSortTerm(request, null);
    if (objectId) {
      request.objectId = objectId;
    }
    if (objectType) {
      request.objectType = objectType;
    }
    if (parentObjectId) {
      request.parentObjectId = parentObjectId;
    }
    if (parentObjectType) {
      request.parentObjectType = parentObjectType;
    }
    if (messageId) {
      request.messageIds = messageId;
    }
    this.searchHistory(request);
  };

  searchHistory(request) {
    const { url } = this.props;
    RestClient.sendGetRequestWithParameters(
      url,
      request,
      (response) => {
        this.setState({
          data: response.data,
          total: response.total,
        });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  showDetails = (flag) => {
    this.setState({
      showDetail: flag,
      title: flag ? "History Details" : "History",
    });
  };

  setLocation = (event) => {
    this.setState({ currentLocation: event, message: event });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  isJson = (str) => {
    if (!str) {
      return false;
    }
    try {
      const parsed = JSON.parse(str);
      return parsed !== null && typeof parsed === "object";
    } catch (e) {
      return false;
    }
  };

  getLocation = (locationId) => {
    const { locations } = this.state;
    const location = locations.find((l) => l.id === locationId);
    return location.name;
  };

  getTimeFrameString = (value) => {
    return `From ${value.From} To ${value.To}`;
  };

  renderHistoryDetailTableBody = () => {
    const { message } = this.state;
    return (
      <tbody>
        <tr>
          <td style={{ whiteSpace: "unset" }}>{message.oldValue}</td>
          <td style={{ whiteSpace: "unset" }}>
            {this.isJson(message.newValue)
              ? Object.entries(JSON.parse(message.newValue)).map(
                  ([key, value]) => {
                    if (key === HallTransactionEnquiryKeys.LocationId) {
                      value = this.getLocation(value);
                    }
                    if (key === HallTransactionEnquiryKeys.TimeFrame) {
                      value = this.getTimeFrameString(value);
                    }
                    return (
                      <p>
                        <span>
                          {HallTransactionEnquiryKeys.getName(key)}:
                          {value?.toString()}
                        </span>
                      </p>
                    );
                  }
                )
              : message.newValue}
          </td>
        </tr>
      </tbody>
    );
  };

  render() {
    const { data, pageIndex, pageSize, total, showDetail, message, title } =
      this.state;
    const {
      objectId,
      objectType,
      parentObjectId,
      parentObjectType,
      messageId,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <div className="table-responsive m-b-20 table-px-15 modal-max-height">
                      {!showDetail && (
                        <table
                          className="table table-borderless table-striped table-earning"
                          style={{ tableLayout: "auto" }}
                        >
                          <thead>
                            <tr>
                              <th>Action Changes</th>
                              <th>Action By</th>
                              <th>Action Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item) => (
                              <tr key={item.id}>
                                {((objectId && objectType) ||
                                  (parentObjectId && parentObjectType) ||
                                  (objectId === 0 && objectType) ||
                                  messageId) && (
                                  <td>
                                    {item.message}{" "}
                                    {!item.isConfidential && (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setLocation(item);
                                          this.showDetails(true);
                                        }}
                                      >
                                        view details
                                      </a>
                                    )}
                                  </td>
                                )}
                                <td>{item.createdBy}</td>
                                <td>
                                  {DateHelper.toDisplayDateTimeFormat(
                                    item.createdOn
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      {showDetail && (
                        <div>
                          <h5 className="mb-2">{message.message}</h5>
                          <table
                            className="table table-borderless table-striped table-earning"
                            style={{ tableLayout: "auto" }}
                          >
                            <thead>
                              <tr>
                                <th>Old Value</th>
                                <th>New Value</th>
                              </tr>
                            </thead>
                            {this.renderHistoryDetailTableBody()}
                          </table>
                        </div>
                      )}
                    </div>
                    {showDetail && (
                      <div
                        style={{
                          textAlign: "right",
                          alignSelf: "stretch",
                          width: "100%",
                        }}
                      >
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.showDetails(false);
                          }}
                        >
                          Back
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {!showDetail && (
                  <div className="d-flex justify-content-between">
                    <Paginator
                      onPageIndexChange={this.onPageIndexChange}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      total={total}
                    />
                    <div>
                      <PageSizeSelector
                        onPageSizeChange={this.onPageSizeChange}
                        pageSize={pageSize}
                        selectors={[10, 25, 50, 100]}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    );
  }
}
