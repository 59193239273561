import { RestService } from "./RestService";

export class EVoucherBatchRestService extends RestService {
  constructor() {
    super("api/v1/evoucher_batches");
  }

  async download(id) {
    return this.downloadImpl(`${id}/download`);
  }

  async reject(id, payload) {
    return this.postImpl(`${id}/reject`, payload);
  }

  async cancel(id, payload) {
    return this.postImpl(`${id}/cancel`, payload);
  }

  async approve(id) {
    return this.postImpl(`${id}/approve`, {});
  }
}
