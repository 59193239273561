import React from "react";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { EvoucherBatchInformationCard } from "./cards/EvoucherBatchInformationCard";
import { EvoucherBatchMode } from "../common/EvoucherBatchMode";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class EvoucherBatchCreate extends React.Component {
  constructor(props) {
    super(props);
    const { orderId, generalInfo } = props.location.state ?? {};
    this.state = {
      evoucherBatch: null,
      isClear: false,
      isShowCancelDialog: false,
      isShowConfirmDialog: false,
      isShowClearDialog: false,
      evoucherBatchValidations: [],
      isSubmitted: false,
      generalInfo: generalInfo,
      orderId: orderId,
    };
  }

  isValidated() {
    const { evoucherBatchValidations } = this.state;
    return evoucherBatchValidations.every((val) => val.isValid);
  }

  toggleConfirmDialog = (visible) => {
    if (!this.isValidated()) {
      this.setState({ isSubmitted: true });
      return;
    }
    this.setState({ isShowConfirmDialog: visible });
  };

  toggleClearDialog = (visible) => {
    this.setState({ isShowClearDialog: visible });
  };

  onEvoucherBatchValidationsChange = (validations) => {
    this.setState({ evoucherBatchValidations: validations });
  };

  toggleCancelDialog = (visible) => {
    this.setState({ isShowCancelDialog: visible });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage(this.state.orderId);
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/evouchers/orders/${additionalParameter ?? ""}`);
  }

  redirectToDetailPage(id) {
    this.props.history.push(`/evouchers/batches/${id ?? ""}`);
  }

  onProceedClearDialog = () => {
    this.setState({ isClear: !this.state.isClear, isShowClearDialog: false });
  };
  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmitted: true, isShowConfirmDialog: false });
      return;
    }
    const { evoucherBatch } = this.state;
    const request = {
      ...evoucherBatch,
    };
    RestClient.sendPostRequest(
      "/api/v1/evoucher_batches/",
      request,
      (response) => {
        this.setState({
          isShowConfirmDialog: false,
        });
        toast.success("eVoucher Batch have been created successfully.");
        this.redirectToDetailPage(response.id);
      },
      (errorResponse) => {
        this.setState({
          isShowConfirmDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  onEvoucherBatchModelChange = (model) => {
    this.setState({ evoucherBatch: model });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-batch-creation",
        name: "Create Voucher Batch",
      }),
    });
  };

  render() {
    const {
      evoucherBatch,
      isSubmitted,
      isShowCancelDialog,
      isShowConfirmDialog,
      isShowClearDialog,
      isClear,
      generalInfo,
    } = this.state;

    if (!generalInfo) return <></>;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <EvoucherBatchInformationCard
                  cardMode={EvoucherBatchMode.Create}
                  isSubmitted={isSubmitted}
                  generalInfo={generalInfo}
                  model={evoucherBatch}
                  isClear={isClear}
                  onModelChange={this.onEvoucherBatchModelChange}
                  onValidationsChange={this.onEvoucherBatchValidationsChange}
                />
              </div>
            </div>
            <ButtonActionDetailGroup className="d-flex flex-row justify-content-end pb-3">
              <ButtonActionDetailGroup.Item
                className="btn btn-success"
                onClick={() => this.toggleConfirmDialog(true)}
              >
                Submit for approval
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={() => this.toggleClearDialog(true)}
              >
                Clear
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={() => this.toggleCancelDialog(true)}
              >
                Cancel
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        </div>
        <ConfirmDialog
          visible={isShowCancelDialog}
          onCancel={() => this.toggleCancelDialog(false)}
          onProceed={this.onProceedCancelDialog}
          message="Are you sure you want to cancel the creation of eVoucher Batch?"
          title="Cancel eVoucher Batch creation"
        />
        <ConfirmDialog
          visible={isShowConfirmDialog}
          onCancel={() => this.toggleConfirmDialog(false)}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to submit for approval eVoucher Batch?"
          title="Submit for approval"
        />
        <ConfirmDialog
          visible={isShowClearDialog}
          onCancel={() => this.toggleClearDialog(false)}
          onProceed={this.onProceedClearDialog}
          message="Are you sure you want to clear details for eVoucher Batch Details?"
          title="Clear eVoucher Batch Details"
        />
      </div>
    );
  }
}
