export class Customer {
  constructor(data) {
    this.id = data?.id || 0;
    this.name = data?.name || "";
    this.emailAddress = data?.emailAddress || "";
    this.contactNumber = data?.contactNumber || "";
    this.lifetimeValue = data?.lifetimeValue || 0;
    this.transactions = data?.transactions || 0;
    this.refunds = data?.refunds || 0;
    this.memberId = data?.memberId || "";
    this.kinoloungeMembershipId = data?.kinoloungeMembershipId || "";
  }
}