import React, { useEffect, useState } from "react";

export const FileDownloadModal = (props) => {
  const { onSubmit, onCancel, title } = props;
  const [password, setPassword] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const submit = () => {
    if (password) {
      onSubmit(password);
      return;
    }
    setShowErrorMessage(true);
  };

  useEffect(() => {
    setShowErrorMessage(false);
  }, [password]);

  return (
    <>
      <div className="modal fade show" role="dialog" aria-hidden="true">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label className="form-control-label"></label>
              <div className="row form-group">
                <label className="col-md-3 form-control-label" htmlFor="Reason">
                  Password:
                </label>
                <div className="col-md-9">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="form-control"
                    autoComplete={"new-password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {showErrorMessage && (
                    <label className="status--denied">
                      Password is required.
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={submit}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
