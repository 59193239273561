import axios from "axios";
import { AuthenticationManager } from "../common/AuthenticationManager";
import { toast } from "react-toastify";
import { StorageKeys } from "../common/Constants";
import { isEmpty } from "../common/Common";

export class RestService {
  constructor(baseUrl, contentType, errorHandler) {
    this.baseUrl = baseUrl;

    const headers = {
      "Content-type": contentType ?? "application/json",
    };

    if (AuthenticationManager.accessToken()) {
      headers.authorization = `Bearer ${AuthenticationManager.accessToken()}`;
    }

    this.axios = axios.create({
      baseURL: baseUrl,
      headers,
    });

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (response.status === 401) {
          localStorage.setItem(
            StorageKeys.ReturnPath,
            window.location.pathname
          );
          window.location.href = "/login";
          return;
        }

        if (response.status === 400) {
          const { message, title } = response.data;
          if (message) {
            toast.error(message);
          } else {
            toast.error(title);
          }
          return;
        }

        if (errorHandler && errorHandler(response)) {
          return;
        }

        toast.error(
          "An unexpected error occurs. Fail to request to the server."
        );
        console.error({ response });
      }
    );
  }

  async get(objectId, params) {
    return await this.getImpl(isEmpty(objectId) ? "" : `/${objectId}`, params);
  }

  async post(body, params) {
    return await this.postImpl("", body, params);
  }

  async download(params) {
    return await this.downloadImpl("", params);
  }

  async patch(objectId, body, params) {
    return await this.patchImpl(`/${objectId}`, body, params);
  }

  async put(objectId, body, params) {
    return await this.putImpl(`/${objectId}`, body, params);
  }

  async delete(objectId, params) {
    return await this.deleteImpl(`/${objectId}`, params);
  }

  async list(params) {
    return await this.axios.get("", { params });
  }

  async getImpl(url, params) {
    return await this.axios.get(url, { params });
  }

  async patchImpl(url, body, params) {
    return await this.axios.patch(url, body, { params });
  }

  async postImpl(url, body, params) {
    return await this.axios.post(url, body, { params });
  }

  async downloadImpl(url, params) {
    return await this.axios.get(url, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        "Content-Type": "application/zip",
      },
      responseType: "blob",
    });
  }

  async putImpl(url, body, params) {
    return await this.axios.put(url, body, { params });
  }

  async deleteImpl(url, params) {
    return this.axios.delete(url, { params });
  }
}
