import React from "react";
import { RowFormField } from "../../../layout/FormLayout";

const TransactionDetailCustomerInformation = (props) => {
  const { customer, transaction } = props;

  const defaultMembershipId = "00000000-0000-0000-0000-000000000000";

  return (
    <div className="row">
      <div className="col-xl-7">
        <RowFormField label="Customer Name:" htmlFor="CustomerName">
          <input
            className="form-control display-input"
            type="text"
            id="CustomerName"
            value={customer.name}
            disabled
          />
        </RowFormField>
        <RowFormField label="Email Address:" htmlFor="Email">
          <input
            className="form-control display-input"
            type="text"
            id="Email"
            value={transaction.emailAddress}
            disabled
          />
        </RowFormField>
        <RowFormField label="Contact Number:" htmlFor="ContactNo">
          <input
            className="form-control display-input"
            type="tel"
            id="ContactNo"
            value={customer.contactNumber}
            disabled
          />
        </RowFormField>
        <RowFormField label="Membership Id:" htmlFor="MemberId">
          <label className="form-control-label" style={{ color: "#000000" }}>
            {customer.memberId === defaultMembershipId
              ? ""
              : customer.memberId}
          </label>
        </RowFormField>
        <RowFormField label="Kinolounge Membership Id:" htmlFor="MemberId">
          <label className="form-control-label" style={{ color: "#000000" }}>
            {customer.kinoloungeMembershipId === defaultMembershipId
              ? ""
              : customer.kinoloungeMembershipId}
          </label>
        </RowFormField>
      </div>
      <div className="col-xl-5">
        <div className="card mb-1">
          <div className="card-body text-center py-2 px-3">
            <label
              className="form-control-label col-12"
              style={{ color: "#000000", fontSize: "30px" }}
            >
              ${(customer.lifetimeValue ?? 0).toFixed(2)}
            </label>
            <label className="form-control-label col-12" htmlFor="MemberId">
              Lifetime value
            </label>
          </div>
        </div>
        <div className="card-body text-md-right py-2 pr-3">
          <div className="row">
            <div className="col">
              <label className="form-control-label" htmlFor="MemberId">
                Transactions:&nbsp;
              </label>
              <label
                className="form-control-label"
                style={{ color: "#000000" }}
              >
                {customer.transactions}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-control-label" htmlFor="MemberId">
                Refunds:&nbsp;
              </label>
              <label
                className="form-control-label"
                style={{ color: "#000000" }}
              >
                {customer.refunds}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailCustomerInformation;
