import { DateHelper } from "../../../common/Helpers";
import { FileHelper } from "../../../common/FileHelper";

export class EVoucherExporter {
  static toProtectedZipReporting = async (data, password) => {
    const exportedData = data.map((eVoucher) => {
      return {
        Code: eVoucher.code,
        "Valid On": DateHelper.toDisplayDateTimeFormat(eVoucher.validityStart),
        "Valid Till": DateHelper.toDisplayDateTimeFormat(eVoucher.validityEnd),
        Status: eVoucher.status,
        "Internal Running Number": eVoucher.internalId,
        "eVoucher Program Name": eVoucher.eVoucherProgramName,
      };
    });
    await FileHelper.toZipFile(exportedData, password);
  };
}
