import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { generateRandomStringWithDate } from "./Common";

export class FileHelper {
  static EXCEL_EXTENSION = ".xlsx";

  static toExcelBlob = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
  };

  static getFileName = (fileName) =>
    fileName ? fileName : generateRandomStringWithDate();

  static toExcelFile = async (data, customFileName) =>
    FileSaver.saveAs(
      data,
      `${FileHelper.getFileName(customFileName)}${FileHelper.EXCEL_EXTENSION}`
    );

  static toZipFile = async (data, password, customFileName) => {
    const excelBlob = FileHelper.toExcelBlob(data);
    const writer = new window.zip.ZipWriter(new window.zip.BlobWriter(), {
      password,
    });
    const fileName = FileHelper.getFileName(customFileName);
    await writer.add(
      `${fileName}${FileHelper.EXCEL_EXTENSION}`,
      new window.zip.BlobReader(excelBlob)
    );
    const zipBlob = await writer.close();
    FileSaver.saveAs(zipBlob, `${fileName}.zip`);
  };
}
