import React from "react";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import { EvoucherBatchInformationCard } from "./cards/EvoucherBatchInformationCard";
import { RejectEvoucherBatchExtensionRequestDialog } from "./dialogs/RejectEvoucherBatchExtesionRequestDialog";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelEvoucherBatchExtensionRequestDialog } from "./dialogs/CancelEvoucherBatchExtesionRequestDialog";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { EvoucherBatchExtensionRequestCard } from "./cards/EvoucherBatchExtensionRequestCard";
import { EVoucherBatchExtensionRequestStatus } from "../../../common/Constants";
import { EVoucherHistoryDialog } from "../../common/EVoucherHistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class EvoucherBatchExtensionRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generalInfoValidations: [],
      evoucherBatchExtensionRequest: null,
      evoucherBatchExtensionRequestStatus: "",
      evoucherBatchExtensionRequestId: "",
      redirect: false,
      showRejectDialog: false,
      showCancelDialog: false,
      showApproveDialog: false,
      isApprove: false,
      showEVoucherBatchExtensionHistoryDialog: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherBatchExtensionRequestId = StringHelper.toNumber(id, 0);
    if (evoucherBatchExtensionRequestId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ evoucherBatchExtensionRequestId }, this.refreshData);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(
      `/evouchers/batch_extension_requests/${additionalParameter ?? ""}`
    );
  }

  refreshData() {
    const { evoucherBatchExtensionRequestId } = this.state;
    RestClient.sendGetRequest(
      `/api/v1/evoucher_batch_extension_requests/${evoucherBatchExtensionRequestId}`,
      (response) => {
        if (response) {
          this.setState({
            evoucherBatchExtensionRequest: response,
            evoucherBatchExtensionRequestStatus: response.status,
          });
        }
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  onHideApproveDialog = () => {
    this.setState({ showApproveDialog: false });
  };

  onHideRejectDialog = () => {
    this.setState({ showRejectDialog: false });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onProceedRejectDialog = (reason) => {
    const { evoucherBatchExtensionRequestId } = this.state;
    const request = {
      reason,
    };
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batch_extension_requests/${evoucherBatchExtensionRequestId}/reject`,
      request,
      (response) => {
        this.setState({
          isSubmit: false,
          evoucherBatchExtensionRequest: response,
          showRejectDialog: false,
          evoucherBatchExtensionRequestStatus: response.status,
        });
        toast.success("eVoucher Batch Extension Request has been rejected successfully.");
      },
      (errorResponse) => {
        this.setState({
          showRejectDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  onProceedCancelDialog = (reason) => {
    const { evoucherBatchExtensionRequestId } = this.state;
    const request = {
      reason,
    };
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batch_extension_requests/${evoucherBatchExtensionRequestId}/cancel`,
      request,
      (response) => {
        this.setState({
          evoucherBatchExtensionRequest: response,
          showCancelDialog: false,
          evoucherBatchExtensionRequestStatus: response.status,
        });
        toast.success("eVoucher Batch Extension Request has been canceled successfully.");
      },
      (errorResponse) => {
        this.setState({
          showCancelDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  onProceedApproveDialog = () => {
    const { evoucherBatchExtensionRequestId } = this.state;
    const request = {};
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batch_extension_requests/${evoucherBatchExtensionRequestId}/approve`,
      request,
      (response) => {
        this.setState({
          isSubmit: false,
          evoucherBatchExtensionRequest: response,
          showApproveDialog: false,
          isApprove: true,
          evoucherBatchExtensionRequestStatus: response.status,
        });
        toast.success("eVoucher Batch Extension Request has been approved successfully.");
      },
      (errorResponse) => {
        this.setState({
          showApproveDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  onShowRejectDialog = () => {
    this.setState({ showRejectDialog: true });
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowApproveDialog = () => {
    this.setState({ showApproveDialog: true });
  };

  onViewHistoryClick = () => {
    this.setState({ showEVoucherBatchExtensionHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showEVoucherBatchExtensionHistoryDialog: false });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evoucher-batch-extension-request-details",
        name: "eVoucher Batch Extension Request Details",
      }),
    });
  };

  render() {
    const {
      isSubmit,
      evoucherBatchExtensionRequest,
      evoucherBatchExtensionRequestStatus,
      showRejectDialog,
      showApproveDialog,
      showCancelDialog,
      isApprove,
      showEVoucherBatchExtensionHistoryDialog,
    } = this.state;
    const isOwner =
      evoucherBatchExtensionRequest &&
      evoucherBatchExtensionRequest.createdBy ===
        AuthenticationManager.username();

    const isCancellable =
      isOwner ||
      AuthenticationManager.isAuthorized(
        ResourceID.EVoucherBatchExtensionRequestViewDetailsCancelButton
      );
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                data-target="#UserHistory"
                onClick={this.onViewHistoryClick}
                data-toggle="modal"
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col-md-12">
                <EvoucherBatchInformationCard
                  evoucherBatchId={evoucherBatchExtensionRequest?.batchId}
                  isApprove={isApprove}
                  evoucherBatchExtensionRequestStatus={
                    evoucherBatchExtensionRequestStatus
                  }
                  isSubmit={isSubmit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <EvoucherBatchExtensionRequestCard
                  evoucherBatchExtensionRequest={evoucherBatchExtensionRequest}
                />
              </div>
            </div>
            {evoucherBatchExtensionRequest?.status ===
              EVoucherBatchExtensionRequestStatus.Pending && (
              <ButtonActionDetailGroup>
                {AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherBatchExtensionRequestViewDetailsApproveRejectButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#confirmApproveModal"
                    onClick={this.onShowApproveDialog}
                  >
                    Approve
                  </ButtonActionDetailGroup.Item>
                )}
                {AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherBatchExtensionRequestViewDetailsApproveRejectButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    data-toggle="modal"
                    data-target="#confirmRejectModal"
                    onClick={this.onShowRejectDialog}
                  >
                    Reject
                  </ButtonActionDetailGroup.Item>
                )}
                {isCancellable && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-secondary"
                    data-toggle="modal"
                    data-target="#confirmCancelModal"
                    onClick={this.onShowCancelDialog}
                  >
                    Cancel
                  </ButtonActionDetailGroup.Item>
                )}
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        {showRejectDialog && (
          <RejectEvoucherBatchExtensionRequestDialog
            onCancel={this.onHideRejectDialog}
            onProceed={this.onProceedRejectDialog}
          />
        )}
        <ConfirmDialog
          visible={showApproveDialog}
          onCancel={this.onHideApproveDialog}
          onProceed={this.onProceedApproveDialog}
          message="Are you sure you want to approve?"
          title="Confirmation"
        />
        {showCancelDialog && (
          <CancelEvoucherBatchExtensionRequestDialog
            onCancel={this.onHideCancelDialog}
            onProceed={this.onProceedCancelDialog}
          />
        )}
        {showEVoucherBatchExtensionHistoryDialog && (
          <EVoucherHistoryDialog
            objectId={evoucherBatchExtensionRequest.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.EvoucherBatchExtensionRequest}
            getStatusColor={EVoucherBatchExtensionRequestStatus.getColor}
          />
        )}
      </div>
    );
  }
}
